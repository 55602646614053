import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#333',
    color: 'white',
    padding: '10px 20px',
  };

  const logoStyle = {
    textDecoration: 'none',
    color: 'white',
    fontSize: '24px',
  };

  const menuStyle = {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
    display: 'flex',
  };

  const menuItemStyle = {
    marginRight: '20px',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'white',
  };

  return (
    <header style={headerStyle}>
      <div className="logo">
        <Link to="/" style={logoStyle}>
          MFL Pro
        </Link>
      </div>
      <nav className="menu">
        <ul style={menuStyle}>
          <li style={menuItemStyle}>
            <Link to="/" style={linkStyle}>
              Home
            </Link>
          </li>
          <li style={menuItemStyle}>
            <Link to="/leaderboard" style={linkStyle}>
              Leaderboard
            </Link>
          </li>
          <li style={menuItemStyle}>
            <Link to="/overall" style={linkStyle}>
              Floors
            </Link>
          </li>
          {/* Add more menu items as needed */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
