// api.js
import axios from 'axios';

export async function fetchData(overallMin) {
  const url = `https://z519wdyajg.execute-api.us-east-1.amazonaws.com/prod/listings?limit=25&type=PLAYER&sorts=listing.price&sortsOrders=ASC&status=AVAILABLE&overallMin=${overallMin}&view=full`;

  try {
    const response = await axios.get(url);
    if (response.data && Array.isArray(response.data)) {
      const prices = response.data.map(item => item.price);
      return prices;
    }
    return null;
  } catch (error) {
    console.error(`Error fetching data for overallMin ${overallMin}: ${error.message}`);
    return null;
  }
}

export async function fetchPlayerData(walletAddress) {
  const limit = 400;
  let lastPlayerId = null;

  const playerData = [];
  
  try {
    do {
      const url = `https://z519wdyajg.execute-api.us-east-1.amazonaws.com/prod/players?ownerWalletAddress=${walletAddress}&limit=${limit}` +
                  (lastPlayerId ? `&beforePlayerId=${lastPlayerId}` : '');

      console.log(`Fetching player data from URL: ${url}`);
      const response = await axios.get(url);

      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        lastPlayerId = response.data[response.data.length - 1].id;
        playerData.push(...response.data);
        console.log(`Fetched ${response.data.length} players. Total players: ${playerData.length}`);
      } else {
        console.log('No more players to fetch.');
        break;
      }
    } while (true);

    if (playerData.length === 0) {
      console.log('No player data found.');
      return null;
    }

    return playerData;
  } catch (error) {
    console.error(`Error fetching player data for wallet address ${walletAddress}: ${error.message}`);
    return null;
  }
}
