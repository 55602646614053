import React from 'react';
import './App.css';
import HomePage from './components/HomePage';
import LeaderboardPage from './components/LeaderboardPage';
import FloorsByOverallPage from './components/FloorsByOverallPage'; // Add this import
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/overall" element={<FloorsByOverallPage />} /> {/* Add this line */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
