import React, { useState, useEffect } from 'react';
import { fetchPlayerData } from '../api'; // Assuming you have an 'api' module for fetching player data
import axios from 'axios';
import Header from './Header';

function HomePage() {
  const [walletAddress, setWalletAddress] = useState('');
  const [playerData, setPlayerData] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ovr2Data, setOvr2Data] = useState({}); // State to store ovr2.json data

  const handleInputChange = (event) => {
    setWalletAddress(event.target.value);
  };

  const fetchPricesForOVRs = async () => {
    try {
      const response = await axios.get('https://mflpro.com:9000/ovr2.json');
      return response.data;
    } catch (error) {
      console.error('Error fetching ovr2.json data:', error);
      return {};
    }
  };

  const calculateValue2 = (ovr, firstPosition) => {
    // Use the ovr2.json data to calculate Value2
    const value2 = ovr2Data[ovr] && ovr2Data[ovr][firstPosition] ? ovr2Data[ovr][firstPosition] : 0;
    return value2;
  };

const handleSearchClick = async () => {
  if (walletAddress) {
    setLoading(true);

    const ovrPriceMap = await fetchPricesForOVRs();
    const walletPlayers = await fetchPlayerData(walletAddress);

    if (walletPlayers !== null) {
      const ovrCaps = {
        94: 2500,
        93: 2000,
        92: 2000,
        91: 1900,
        90: 1500,
        89: 1410,
        88: 999,
        87: 825,
      };

      const playerInfo = walletPlayers.map((player) => {
        const playerName = `${player.metadata.firstName} ${player.metadata.lastName}`;
        const ovr = player.metadata.overall;
        const firstPosition = player.metadata.positions[0]; // Get the first position

        // Calculate price, use the default value if it exists in ovrPriceMap, otherwise 0
        const price = ovrPriceMap[ovr] && ovrPriceMap[ovr][firstPosition] ? ovrPriceMap[ovr][firstPosition] : 0;

        // Find the minimum price among all positions for this OVR
        const minPricesForOvr = Object.values(ovrPriceMap[ovr]);
        const minPriceForOvr = Math.min(...minPricesForOvr.filter((price) => price > 0));

        // Calculate value2, and if it's 0, set it to minPriceForOvr; apply caps only if the OVR has a cap defined
        let value2 = calculateValue2(ovr, firstPosition);
        if (value2 === 0) {
          value2 = minPriceForOvr;
        }

        // Apply OVR-specific caps only if the OVR has a cap defined
        const cap = ovrCaps[ovr];
        const cappedMinPriceForOvr = cap ? (minPriceForOvr < cap ? minPriceForOvr : cap) : minPriceForOvr;
        const cappedValue2 = cap ? (value2 > cap ? cap : value2) : value2;

        return {
          playerName,
          ovr,
          price: cappedMinPriceForOvr,
          value2: cappedValue2,
          firstPosition,
        }; // Store the firstPosition
      });

      playerInfo.sort((a, b) => {
        if (a.price === b.price) {
          return b.ovr - a.ovr;
        }
        return b.price - a.price;
      });

      setPlayerData(playerInfo);

      const total = playerInfo.reduce((acc, player) => acc + player.price, 0);
      setTotalValue(total);
    } else {
      console.log('No player data found.');
      setTotalValue(0);
    }

    setLoading(false);
  }
};

  const handleUrlParameter = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramWalletAddress = urlParams.get('walletAddress');
    if (paramWalletAddress) {
      setWalletAddress(paramWalletAddress);
      handleSearchClick();
    }
  };

  useEffect(() => {
    handleUrlParameter();
  }, []);

  useEffect(() => {
    if (walletAddress) {
      handleSearchClick();
    }
  }, [walletAddress]);

  useEffect(() => {
    // Fetch ovr2.json data when the component mounts
    const fetchOvr2 = async () => {
      const ovr2Data = await fetchPricesForOVRs();
      setOvr2Data(ovr2Data);
    };
    fetchOvr2();
  }, []);

return (
<div>
  <Header />
  <div className="container mt-5">
    <div className="form-group">
      <label htmlFor="walletAddress">Enter Wallet Address:</label>
      <input
        type="text"
        id="walletAddress"
        className="form-control"
        value={walletAddress}
        onChange={handleInputChange}
      />
      <button
        className="btn btn-primary mt-2"
        onClick={handleSearchClick}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Search'}
      </button>
    </div>
    {playerData.length > 0 && (
      <div className="mt-4">
        <div className="mb-3">
          <strong>Total Players: {playerData.length}</strong>
        </div>
        <div>
          <strong>Total Value: ${totalValue}</strong>
        </div>
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Player Name</th>
                <th>POS</th> {/* Add the Position column */}
                <th>OVR</th>
                <th>OVR Floor</th>
                <th>OVR + POS Floor</th>
              </tr>
            </thead>
            <tbody>
              {playerData.map((player, index) => (
                <tr key={index}>
                  <td>{player.playerName}</td>
                  <td>{player.firstPosition}</td> {/* Display the player's first position */}
                  <td>{player.ovr}</td>
                  <td>${player.price.toFixed(2)}</td>
                  <td>${player.value2.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </div>
  </div>
);

}

export default HomePage;
