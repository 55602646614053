import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';

function FloorsByOverallPage() {
  const [floorsData, setFloorsData] = useState([]);

  useEffect(() => {
    const fetchFloorsData = async () => {
      try {
        const response = await axios.get('https://mflpro.com:9000/ovr2.json');
        const data = response.data;

        // Convert the object into an array of objects with "OVR" and "Floor" properties
        const floorsArray = Object.entries(data).map(([ovr, floorsObj]) => ({
          OVR: ovr,
          Floor: Math.min(...Object.values(floorsObj)), // Find the minimum floor value
        }));

        // Sort the array by OVR in ascending order
        floorsArray.sort((a, b) => a.OVR - b.OVR);

        setFloorsData(floorsArray);
      } catch (error) {
        console.error('Error fetching floors data:', error);
      }
    };

    fetchFloorsData();
  }, []);

  return (
  <div>
  <Header />
    <div className="container mt-5">
      <h1 className="mb-4">Floors by Overall</h1>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>OVR</th>
              <th>Floor</th>
            </tr>
          </thead>
          <tbody>
            {floorsData.map(({ OVR, Floor }) => (
              <tr key={OVR}>
                <td>{OVR}</td>
                <td>${Floor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
	</div>
  );
}

export default FloorsByOverallPage;
