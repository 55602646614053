import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';

function LeaderboardPage() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await axios.get('https://mflpro.com:9000/leaderboard.json');
        const data = response.data;

        // Convert the object into an array of objects with "Wallet," "Name," and "Value" properties
        const leaderboardArray = Object.entries(data).map(([wallet, value]) => ({
          Wallet: wallet,
          Name: value.name, // Assuming "name" contains the name from your API
          Value: value.totalValue, // Assuming "totalValue" contains the value from your API
        }));

        // Sort the array by value in descending order
        leaderboardArray.sort((a, b) => b.Value - a.Value);

        // Assign ranks after sorting
        leaderboardArray.forEach((entry, index) => {
          entry.Rank = index + 1;
        });

        setLeaderboardData(leaderboardArray);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    fetchLeaderboardData();
  }, []);

  // Function to format a number as USD currency with commas and without ".00"
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0, // Remove decimal places
    }).format(value);
  };

  return (
  <div>
  <Header />
    <div className="container mt-5">
      <h1 className="mb-4">Leaderboard</h1>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Wallet</th>
              <th>Name</th>
              <th>Floor Value</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.map(({ Rank, Wallet, Name, Value }) => (
              <tr key={Wallet}>
                <td>#{Rank}</td>
                <td>{Wallet}</td>
                <td>{Name}</td>
                <td>{formatCurrency(Value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
	</div>
  );
}

export default LeaderboardPage;
